.alm-load-more-btn {
  display: inline-block;
  margin-top: 50px;
  background: none!important;
  border: none!important;
  cursor: pointer;
  outline: none;
  height: auto!important;
}

.alm-btn-wrap {
  text-align: center;

  .done {
    display: none!important;
  }
}

.alm-load-more-btn.loading {
  display: none!important;
}

.alm-load-more-btn {
  padding: 0!important;
  margin: 0!important;
  line-height: 0!important;
}