/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  font-size: 0.875rem;
  padding: 2rem 1rem 1rem;
  background: $color-footer-bg;
}

.c-footer__copy {
  margin-top: 4rem;
  color: $color-white;
}

.c-footer__item {
  &--logo {
    svg {
      width: 170px;
    }

    path {
      fill: $color-white;
    }
  }
}

.c-footer__wrapper {
  @include bp(medium) {
    display: flex;
  }
}

.c-footer__item {
  margin-top: 3rem;

  &:first-child {
    margin-top: 0;
  }

  @include bp(medium) {
    margin-top: 0;
    width: 25%;
  }
}

.c-footer__item-text {
  line-height: 1.9;
}

.c-footer__item-icon {
  width: 1.5rem;
  margin-right: 1rem;
}

.c-footer__info {
  display: flex;
  color: $color-white;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  @include bp(medium) {
    margin-top: 0.3rem;
  }

  a {
    color: $color-white;
    text-decoration: none;
  }
}

.c-footer__pfr {
  width: 100%;
  padding-left: 1rem;
  max-width: 1200px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
