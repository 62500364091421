/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

  .c-drop-menu {
    display: block;
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    z-index: 5;
    background: rgba($color-medium-grey, 0.95);
    padding-top: 5em;
    padding-left: 1.5em;
    transition: top 0.3s ease-in-out;
    height: 100vh;
    overflow: hidden;
    z-index: 999999999;

    &--show {
      top: 0;
    }
  }

  .c-drop-menu__logo {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.85;
    color: $color-black;
    text-decoration: none;

    &:hover {
      color: $color-black;
    }
  }

  .c-drop-menu__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }

  .c-drop-menu__items {
    margin-top: 50px;
  }

  .c-drop-menu__close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    span {
      display: inline-block;
      position: absolute;
      width: 3px;
      height: 100%;
      transform: rotate(45deg);
      background: $color-white;
      left: 0;
      right: 0;
      margin: 0 auto;

      &:first-child {
        transform: rotate(-45deg);
      }
    }
  }

  .c-drop-menu__item {
    font-weight: 700;
    list-style: none;
    margin: 0;
    padding: 0.2em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.3rem;
    text-align: center;
    line-height: 1.8;
  }

  .c-drop-menu__link {
    display: inline;
    text-decoration: none;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  .c-drop-menu__logo {
    font-size: 1.6rem;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  .c-drop-menu__social {
    display: flex;
    justify-content: center;
    svg {
      fill: $color-white;;

      width: 1.6rem;

      position: absolute;
    }

    path, rect {
      fill: $color-white;;
    }

    .c-topbar__social {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: $color-white;
      position: relative;
    }
  }