.c-form {
  margin: 50px auto;

  @include bp(medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 800px;
    width: 100%;
  }

  &--newsletter {
    margin: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.c-form__group {
  &--input {
    @include bp(small) {
      display: flex;
      justify-content: space-between;
    }
    width: 100%;
  }

  &--textarea {
    width: 100%;
  }
}

.c-form__input, .tnp-field-email {
  margin-top: 2rem;
  padding: 0;

  @include bp(small) {
    margin: 0;
    width: 48%;
  }

  input {
    color: $color-black;
    font-size: 0.85rem;
    font-weight: 400;

    width: 100%;
    padding: 1rem;

    background: $color-white;
    border: none;
    border: 1px solid $color-black;

    &::placeholder {
      color: $color-black;
    }
  }
}

.tnp-field-email {
  margin: 0;
  @include bp(medium) {
    flex: 1;
  }

  padding-right: 1rem;

  input {
    padding: 0.5rem 1rem;
    height: 100%;
  }
}

input, textarea {
  outline: none;
}

.c-form__textarea {
  margin-top: 2rem;

  textarea {
    color: $color-black;
    font-size: 0.85rem;

    font-weight: 400;
    padding: 1rem;

    width: 100%;

    resize: none;
    background: $color-white;
    border: none;
    border: 1px solid $color-black;

    &::placeholder {
      color: $color-black;
    }
  }
}

.c-form__accept {
  margin-top: 2rem;

  line-height: 1;
}

.c-form__accept-explain {
  font-size: 0.85rem;
  font-weight: 400;
  display: block;

  color: $color-black;
  letter-spacing: 1px;
}

.c-form__label {
  display: flex;
  align-items: center;
}

.c-form__checkbox {
  margin-top: 5px;
  color: $color-black;
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: 1px;

  label {
    margin-left: 3px;
  }

  a {
    color: $color-black;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
  }
}

input[type="checkbox"] {
  position: absolute;
  z-index: -2222;

  &:checked + .c-form__label {
    .c-form__real-checkbox {
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 6px;
        height: 6px;

        left: 0;
        right: 0;
        margin: 0 auto;

        top: 50%;
        transform: translateY(-50%);
        background: $color-red;
      }
    }
  }
}

.c-form__send {
  display: block;
  margin: 1.5rem auto 2rem;
  background: $color-black;
  border: none;
  color: $color-white!important;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 0;

  padding: 0.5rem 2rem!important;

  text-transform: uppercase;
}

.c-form__real-checkbox {
  display: inline-block;
  position: relative;

  cursor: pointer;

  width: 10px;
  height: 10px;

  margin-right: 5px;

  border: 1px solid $color-red;
}

.tnp-field {
  label {
    display: none;
  }
}