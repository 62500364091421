.c-product-first-section {
  padding: 0 1rem;
  margin: 3rem auto;

  @include bp(medium) {
    margin: 5rem auto;
    max-width: 1030px;
    display: flex;
  }
}

.c-product-first-section__text {
  margin: 2rem auto;

  @include bp(medium) {
    width: 50%;
    padding: 2rem 4rem 2rem 0;
  }
}

.c-product-first-section__image {
  @include bp(medium) {
    width: 50%;
  }
}

.c-product-first-section__title {
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.c-product-first-section__content {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.65;
}