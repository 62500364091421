.c-products__wrapper {
  display: flex;
  max-width: 1100px;
  margin: 5rem auto 0!important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.c-products__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  width: 49%;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;
  text-align: center;

  &:hover {
    .c-circle-icon {
      transform: translateY(-0.3rem);
    }
  }

  @media(min-width: 550px) {
    width: 32%;
  }

  @include bp(medium) {
    margin-top: 0.5rem;
    width: 18%;
    flex: 0 0 auto;
  }
}

.c-products__item-title {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  margin-top: 0.5rem;
  color: $color-black;
  letter-spacing: 0;
  line-height: 1.2;
}

.c-circle-icon {
  transition: all 0.3s ease-in-out;
}