.c-contact {
  padding: 0 15px;
  background: $color-super-light-grey;
  overflow-y: hidden;
}

.c-contact__info-block {
  margin: 70px auto 0;

  max-width: 800px;

  @include bp(medium) {
    display: flex;
    justify-content: space-between;
  }
}

.c-contact__info-block__item {
  display: flex;

  align-items: center;

  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }

  text-align: center;

  @include bp(small) {
    text-align: left;
  }

  @include bp(medium) {
    margin-top: 0;
    width: 49%;
  }
}

.c-contact__info-block__icon {
  width: 15px;
  display: none;

  @include bp(small) {
    display: block;
  }
}

.c-contact__info-block__info {
  position: relative;

  width: 100%;

  @include bp(small) {
    width: auto;
    padding-left: 50px;
  }

  &:before {
    content: "";
    position: absolute;
    display: none;

    @include bp(small) {
      display: block;
    }

    left: 20px;
    height: 100%;
    width: 1px;
    background: $color-black;
  }
}

.c-contact__info-block__title {
  color: $color-black;

  font-size: 0.7rem;
  margin: 0;
  text-transform: uppercase;
}

.c-contact__info-block__link {
  color: $color-black;
  font-size: 1.5rem;
  text-decoration: none;
}

.c-contact__rodo {
  font-size: 1rem;
  color: $color-black;
  text-align: center;
  line-height: 1.6;
  max-width: 800px;
  margin: 2rem auto;

  a {
    color: $color-black;
    font-weight: 700;
  }
}

.c-contact__social {
  display: flex;
  justify-content: center;



  .c-topbar__social {
    opacity: 0.6;

    &:hover {
      opacity: 0.85;
    }
  }

  margin-top: 60px;
  padding-bottom: 40px;
}

.c-form__message {
  border: 1px solid $color-black;
  padding: 2rem;
  text-align: center;
  display: block;
  max-width: 300px;
  margin: 0 auto;
}