.c-product__btn-group {
  background: $color-super-light-grey;
  padding: 2rem 1rem;
  text-align: center;

  .c-btn {
    margin: 1rem 0.5rem 0;

    &.no-margin {
      margin: 0 0.5rem;
    }
  }
}