@charset "UTF-8";
/*
  Project: Ginger
  Author: Daniel
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap");
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

body {
  padding-top: 80px;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #4d4d4f;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}

a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-heading {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
}

.o-heading__title {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.3;
}

.o-heading__title--white {
  color: #fff;
}

.o-heading__subheading {
  font-size: 1rem;
  max-width: 600px;
  line-height: 1.6;
  margin: 1rem auto 0;
}

.o-heading__subheading--white {
  color: #fff;
}

.o-heading__subheading--faded {
  color: #4d4d4f;
}

.o-heading__subheading--uppercase {
  text-transform: uppercase;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-about-us {
    display: flex;
  }
}

@media (min-width: 768px) {
  .c-about-us__image,
  .c-about-us__content {
    width: 50%;
  }
}

.c-about-us__image {
  background-size: cover;
}

.c-about-us__image .c-about-us__ph {
  padding-bottom: 100%;
}

@media (min-width: 768px) {
  .c-about-us__image .c-about-us__ph {
    padding-bottom: 0;
  }
}

.c-about-us__content {
  background: #f5f5f5;
  padding: 2rem;
}

@media (min-width: 768px) {
  .c-about-us__content {
    padding: 5rem 5rem 3rem;
  }
}

.c-about-us__title {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 2.2rem;
}

.c-about-us__content {
  letter-spacing: 1px;
  line-height: 1.6;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn, .tnp-button {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  transition: all 300ms ease-in-out;
  border-radius: 3px;
  border: 1px solid #4d4d4f !important;
  border-radius: 0 !important;
  padding: 0.5rem 1rem !important;
  color: #4d4d4f;
  font-weight: 600;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: 1.4;
  text-decoration: none;
  transition: all 0.3s ease-in;
  color: #4d4d4f !important;
}

.c-btn:hover, .tnp-button:hover {
  background: #4d4d4f !important;
  color: #fff !important;
}

.tnp-button {
  color: #4d4d4f !important;
  background: #fff !important;
  border: 0;
}

.tnp-button:hover {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #666;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #999;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-circle-icon {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 2px solid #4d4d4f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.c-circle-icon--img {
  width: 3.5rem;
  height: 3.5rem;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-contact {
  padding: 0 15px;
  background: #f5f5f5;
  overflow-y: hidden;
}

.c-contact__info-block {
  margin: 70px auto 0;
  max-width: 800px;
}

@media (min-width: 768px) {
  .c-contact__info-block {
    display: flex;
    justify-content: space-between;
  }
}

.c-contact__info-block__item {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
}

.c-contact__info-block__item:first-child {
  margin-top: 0;
}

@media (min-width: 480px) {
  .c-contact__info-block__item {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .c-contact__info-block__item {
    margin-top: 0;
    width: 49%;
  }
}

.c-contact__info-block__icon {
  width: 15px;
  display: none;
}

@media (min-width: 480px) {
  .c-contact__info-block__icon {
    display: block;
  }
}

.c-contact__info-block__info {
  position: relative;
  width: 100%;
}

@media (min-width: 480px) {
  .c-contact__info-block__info {
    width: auto;
    padding-left: 50px;
  }
}

.c-contact__info-block__info:before {
  content: "";
  position: absolute;
  display: none;
  left: 20px;
  height: 100%;
  width: 1px;
  background: #4d4d4f;
}

@media (min-width: 480px) {
  .c-contact__info-block__info:before {
    display: block;
  }
}

.c-contact__info-block__title {
  color: #4d4d4f;
  font-size: 0.7rem;
  margin: 0;
  text-transform: uppercase;
}

.c-contact__info-block__link {
  color: #4d4d4f;
  font-size: 1.5rem;
  text-decoration: none;
}

.c-contact__rodo {
  font-size: 1rem;
  color: #4d4d4f;
  text-align: center;
  line-height: 1.6;
  max-width: 800px;
  margin: 2rem auto;
}

.c-contact__rodo a {
  color: #4d4d4f;
  font-weight: 700;
}

.c-contact__social {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 40px;
}

.c-contact__social .c-topbar__social {
  opacity: 0.6;
}

.c-contact__social .c-topbar__social:hover {
  opacity: 0.85;
}

.c-form__message {
  border: 1px solid #4d4d4f;
  padding: 2rem;
  text-align: center;
  display: block;
  max-width: 300px;
  margin: 0 auto;
}

.c-divider {
  height: 22rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-drop-menu {
  display: block;
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(102, 102, 102, 0.95);
  padding-top: 5em;
  padding-left: 1.5em;
  transition: top 0.3s ease-in-out;
  height: 100vh;
  overflow: hidden;
  z-index: 999999999;
}

.c-drop-menu--show {
  top: 0;
}

.c-drop-menu__logo {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0.85;
  color: #4d4d4f;
  text-decoration: none;
}

.c-drop-menu__logo:hover {
  color: #4d4d4f;
}

.c-drop-menu__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.c-drop-menu__items {
  margin-top: 50px;
}

.c-drop-menu__close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.c-drop-menu__close span {
  display: inline-block;
  position: absolute;
  width: 3px;
  height: 100%;
  transform: rotate(45deg);
  background: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c-drop-menu__close span:first-child {
  transform: rotate(-45deg);
}

.c-drop-menu__item {
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;
}

.c-drop-menu__link {
  display: inline;
  text-decoration: none;
  color: #fff;
}

.c-drop-menu__link:hover {
  color: #fff;
}

.c-drop-menu__logo {
  font-size: 1.6rem;
  color: #fff;
}

.c-drop-menu__logo:hover {
  color: #fff;
}

.c-drop-menu__social {
  display: flex;
  justify-content: center;
}

.c-drop-menu__social svg {
  fill: #fff;
  width: 1.6rem;
  position: absolute;
}

.c-drop-menu__social path, .c-drop-menu__social rect {
  fill: #fff;
}

.c-drop-menu__social .c-topbar__social {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #fff;
  position: relative;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 0.875rem;
  padding: 2rem 1rem 1rem;
  background: #414042;
}

.c-footer__copy {
  margin-top: 4rem;
  color: #fff;
}

.c-footer__item--logo svg {
  width: 170px;
}

.c-footer__item--logo path {
  fill: #fff;
}

@media (min-width: 768px) {
  .c-footer__wrapper {
    display: flex;
  }
}

.c-footer__item {
  margin-top: 3rem;
}

.c-footer__item:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-footer__item {
    margin-top: 0;
    width: 25%;
  }
}

.c-footer__item-text {
  line-height: 1.9;
}

.c-footer__item-icon {
  width: 1.5rem;
  margin-right: 1rem;
}

.c-footer__info {
  display: flex;
  color: #fff;
  margin-top: 1rem;
}

.c-footer__info:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-footer__info {
    margin-top: 0.3rem;
  }
}

.c-footer__info a {
  color: #fff;
  text-decoration: none;
}

.c-footer__pfr {
  width: 100%;
  padding-left: 1rem;
  max-width: 1200px;
}

.c-footer__pfr img {
  display: block;
  width: 100%;
  height: auto;
}

.c-form {
  margin: 50px auto;
}

@media (min-width: 768px) {
  .c-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 800px;
    width: 100%;
  }
}

.c-form--newsletter {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.c-form__group--input {
  width: 100%;
}

@media (min-width: 480px) {
  .c-form__group--input {
    display: flex;
    justify-content: space-between;
  }
}

.c-form__group--textarea {
  width: 100%;
}

.c-form__input, .tnp-field-email {
  margin-top: 2rem;
  padding: 0;
}

@media (min-width: 480px) {
  .c-form__input, .tnp-field-email {
    margin: 0;
    width: 48%;
  }
}

.c-form__input input, .tnp-field-email input {
  color: #4d4d4f;
  font-size: 0.85rem;
  font-weight: 400;
  width: 100%;
  padding: 1rem;
  background: #fff;
  border: none;
  border: 1px solid #4d4d4f;
}

.c-form__input input::placeholder, .tnp-field-email input::placeholder {
  color: #4d4d4f;
}

.tnp-field-email {
  margin: 0;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .tnp-field-email {
    flex: 1;
  }
}

.tnp-field-email input {
  padding: 0.5rem 1rem;
  height: 100%;
}

input, textarea {
  outline: none;
}

.c-form__textarea {
  margin-top: 2rem;
}

.c-form__textarea textarea {
  color: #4d4d4f;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 1rem;
  width: 100%;
  resize: none;
  background: #fff;
  border: none;
  border: 1px solid #4d4d4f;
}

.c-form__textarea textarea::placeholder {
  color: #4d4d4f;
}

.c-form__accept {
  margin-top: 2rem;
  line-height: 1;
}

.c-form__accept-explain {
  font-size: 0.85rem;
  font-weight: 400;
  display: block;
  color: #4d4d4f;
  letter-spacing: 1px;
}

.c-form__label {
  display: flex;
  align-items: center;
}

.c-form__checkbox {
  margin-top: 5px;
  color: #4d4d4f;
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.c-form__checkbox label {
  margin-left: 3px;
}

.c-form__checkbox a {
  color: #4d4d4f;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

input[type="checkbox"] {
  position: absolute;
  z-index: -2222;
}

input[type="checkbox"]:checked + .c-form__label .c-form__real-checkbox:before {
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background: #da2128;
}

.c-form__send {
  display: block;
  margin: 1.5rem auto 2rem;
  background: #4d4d4f;
  border: none;
  color: #fff !important;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 0;
  padding: 0.5rem 2rem !important;
  text-transform: uppercase;
}

.c-form__real-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #da2128;
}

.tnp-field label {
  display: none;
}

.c-gallery__wrapper {
  max-width: 840px;
  width: 100%;
  margin: 3rem auto 0 !important;
  display: flex;
  flex-wrap: wrap;
}

.c-gallery__item {
  width: calc(50% - 3rem);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1.5rem;
}

@media (min-width: 768px) {
  .c-gallery__item {
    width: calc(33% - 3rem);
  }
}

.c-gallery__item-ph {
  padding-bottom: 100%;
}

.c-gallery__btn-wrapper {
  display: display;
  text-align: center;
  margin: 0.5rem auto 1.5rem;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  min-height: 60vh;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.c-header--product {
  background: #f5f5f5;
  height: auto;
  padding: 5rem 1rem;
}

.c-header__slider-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin: 0 1rem;
}

.c-header__slider-controls--left, .c-header__slider-controls--right {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 25px;
}

.c-header__slider-controls--left > img, .c-header__slider-controls--right > img {
  width: 16px;
  height: 16px;
}

.c-header__slider-controls--right {
  transform: rotate(180deg);
}

.c-header__slider {
  position: absolute;
  min-height: 60vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.c-header__slider-item {
  min-height: 60vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.c-header-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.c-header-bottom .c-header-scroll {
  display: inline-block;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: #fff;
  padding: 5px;
  text-decoration: none;
}

.c-header-bottom .c-header-scroll--product .svg-mouse {
  fill: #4d4d4f;
}

.c-header-bottom .c-header-scroll span {
  position: relative;
  margin-right: 10px;
  font-weight: 700;
  top: 2px;
  text-transform: uppercase;
}

.c-header-bottom .c-header-scroll img {
  width: 35px;
  height: 35px;
}

.svg-mouse {
  fill: #fff;
  width: 2rem;
  display: block;
  margin: 0 auto;
  animation: arrowMove 1.5s infinite;
}

.svg-mouse--arrow {
  margin-top: 0.3rem;
  width: 0.8rem;
}

@keyframes arrowMove {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(0);
  }
}

.c-header__icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 2px solid #4d4d4f;
  border-radius: 50%;
  position: relative;
}

.c-header__icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 45px;
}

.c-header__products {
  margin: 0;
}

.c-header__product {
  list-style-type: none;
}

.c-header__product a {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0.2rem;
  color: #4d4d4f;
}

.c-header__product a:before {
  content: "▷";
  display: inline;
  margin-right: 10px;
  font-size: 10px;
  vertical-align: middle;
  font-weight: 600;
}

.c-header__product.active a {
  font-weight: 700;
}

.c-header__content-title {
  margin: 0;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #4d4d4f;
}

.c-link-with-icon {
  display: block;
  text-decoration: none;
  color: #4d4d4f;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.c-link-with-icon:hover {
  color: #4d4d4f;
}

.c-link-with-icon__draw {
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #4d4d4f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

.c-link-with-icon__draw--download svg {
  width: 0.9rem;
}

.c-link-with-icon__draw--question:before {
  content: "?";
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0 0 2em 0;
}

.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: #4d4d4f;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #4d4d4f;
}

.c-newsletter--short {
  background: #7F564C;
  padding: 2rem 1.2rem;
  text-align: center;
  color: #fff;
}

@media (min-width: 768px) {
  .c-newsletter--short {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .c-newsletter__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .c-newsletter__text {
    width: 35%;
  }
}

.c-newsletter__form {
  display: flex;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .c-newsletter__form {
    width: 35%;
    margin: 0;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-product-first-section {
  padding: 0 1rem;
  margin: 3rem auto;
}

@media (min-width: 768px) {
  .c-product-first-section {
    margin: 5rem auto;
    max-width: 1030px;
    display: flex;
  }
}

.c-product-first-section__text {
  margin: 2rem auto;
}

@media (min-width: 768px) {
  .c-product-first-section__text {
    width: 50%;
    padding: 2rem 4rem 2rem 0;
  }
}

@media (min-width: 768px) {
  .c-product-first-section__image {
    width: 50%;
  }
}

.c-product-first-section__title {
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.c-product-first-section__content {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.65;
}

.c-product__btn-group {
  background: #f5f5f5;
  padding: 2rem 1rem;
  text-align: center;
}

.c-product__btn-group .c-btn {
  margin: 1rem 0.5rem 0;
}

.c-product__btn-group .c-btn.no-margin {
  margin: 0 0.5rem;
}

.c-products__wrapper {
  display: flex;
  max-width: 1100px;
  margin: 5rem auto 0 !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.c-products__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  width: 49%;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;
  text-align: center;
}

.c-products__item:hover .c-circle-icon {
  transform: translateY(-0.3rem);
}

@media (min-width: 550px) {
  .c-products__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .c-products__item {
    margin-top: 0.5rem;
    width: 18%;
    flex: 0 0 auto;
  }
}

.c-products__item-title {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  margin-top: 0.5rem;
  color: #4d4d4f;
  letter-spacing: 0;
  line-height: 1.2;
}

.c-circle-icon {
  transition: all 0.3s ease-in-out;
}

.alm-load-more-btn {
  display: inline-block;
  margin-top: 50px;
  background: none !important;
  border: none !important;
  cursor: pointer;
  outline: none;
  height: auto !important;
}

.alm-btn-wrap {
  text-align: center;
}

.alm-btn-wrap .done {
  display: none !important;
}

.alm-load-more-btn.loading {
  display: none !important;
}

.alm-load-more-btn {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 0 !important;
}

.c-table__bg {
  background: #f5f5f5;
  padding: 4rem 1rem;
}

.c-table__title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
}

.c-table__wrapper {
  max-width: 1030px;
  width: 100%;
  margin: 0 auto;
}

.c-table {
  border-bottom: 1px solid #4d4d4f;
  margin-bottom: 3rem;
  overflow: auto;
}

.c-table th {
  text-transform: uppercase;
  padding: 1rem 0.5rem;
}

.c-table tr {
  text-align: center;
}

.c-table td {
  padding: 2rem 1rem 0;
}

.c-table td[data-content*="•"] {
  font-size: 3rem;
  line-height: 0;
}

.c-table thead {
  border-top: 1px solid #4d4d4f;
  border-bottom: 1px solid #4d4d4f;
}

.c-topbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 1.3rem;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background: #fff;
  z-index: 99999;
}

.c-topbar__placeholder {
  height: 70px;
  display: block;
}

.c-topbar__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 160px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
}

.c-topbar__logo svg {
  max-width: 160px;
  width: 100%;
}

.c-topbar__left,
.c-topbar__right {
  display: flex;
  align-items: center;
}

.c-topbar__hamburger {
  width: 2rem;
  display: flex;
  flex-direction: column;
  margin-left: 0.85rem;
  cursor: pointer;
}

.c-topbar__hamburger span {
  display: block;
  height: 6px;
  width: 100%;
  background: #4d4d4f;
  margin-top: 5px;
  border-radius: 20px;
}

.c-topbar__hamburger span:first-child {
  margin: 0;
  width: 69%;
}

.c-topbar__icon-round {
  border-radius: 50%;
  border: 1px solid #4d4d4f;
}

.c-topbar__lang-switch {
  margin-left: 1.2rem;
  color: #4d4d4f;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

.c-topbar__lang-switch:hover {
  color: #4d4d4f;
}

.c-topbar__social {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.5rem;
}

.c-topbar__shop-link {
  width: 1.3rem;
  margin-left: 1.2rem;
}

@media (max-width: 480px) {
  .hide-mobile {
    display: none;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.slbOverlay, .slbWrapOuter, .slbWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slbOverlay {
  overflow: hidden;
  z-index: 2000;
  background-color: #000;
  opacity: 0.7;
  -webkit-animation: slbOverlay 0.5s;
  -moz-animation: slbOverlay 0.5s;
  animation: slbOverlay 0.5s;
}

.slbWrapOuter {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2010;
}

.slbWrap {
  position: absolute;
  text-align: center;
}

.slbWrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.slbContentOuter {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0px auto;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 2020;
  text-align: left;
  max-width: 100%;
}

.slbContentEl .slbContentOuter {
  padding: 5em 1em;
}

.slbContent {
  position: relative;
}

.slbContentEl .slbContent {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  background-color: #fff;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.4);
}

.slbImageWrap {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  position: relative;
}

.slbImageWrap:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  bottom: 5em;
  display: block;
  z-index: -1;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background-color: #FFF;
}

.slbDirectionNext .slbImageWrap {
  -webkit-animation: slbEnterNext 0.4s;
  -moz-animation: slbEnterNext 0.4s;
  animation: slbEnterNext 0.4s;
}

.slbDirectionPrev .slbImageWrap {
  -webkit-animation: slbEnterPrev 0.4s;
  -moz-animation: slbEnterPrev 0.4s;
  animation: slbEnterPrev 0.4s;
}

.slbImage {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 5em 0;
  margin: 0 auto;
}

.slbCaption {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 1.4em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.71429em 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.slbCloseBtn, .slbArrow {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}

.slbCloseBtn::-moz-focus-inner, .slbArrow::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.slbCloseBtn:hover, .slbArrow:hover {
  opacity: 0.5;
}

.slbCloseBtn:active, .slbArrow:active {
  opacity: 0.8;
}

.slbCloseBtn {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  font-size: 3em;
  width: 1.66667em;
  height: 1.66667em;
  line-height: 1.66667em;
  position: absolute;
  right: -0.33333em;
  top: 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.slbLoading .slbCloseBtn {
  display: none;
}

.slbLoadingText {
  font-size: 1.4em;
  color: #fff;
  color: rgba(255, 255, 255, 0.9);
}

.slbArrows {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}

.slbLoading .slbArrows {
  display: none;
}

.slbArrow {
  position: absolute;
  top: 50%;
  margin-top: -5em;
  width: 5em;
  height: 10em;
  opacity: 0.7;
  text-indent: -999em;
  overflow: hidden;
}

.slbArrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.8em 0 0 -0.8em;
  border: 0.8em solid transparent;
}

.slbArrow.next {
  right: 0;
}

.slbArrow.next:before {
  border-left-color: #fff;
}

.slbArrow.prev {
  left: 0;
}

.slbArrow.prev:before {
  border-right-color: #fff;
}

.slbIframeCont {
  width: 80em;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 5em 0;
}

.slbIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background: #000;
}

@-webkit-keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-moz-keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnter {
  from {
    opacity: 0;
    -moz-transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
    -moz-transform: translate3d(0, -1em, 0);
    -ms-transform: translate3d(0, -1em, 0);
    -o-transform: translate3d(0, -1em, 0);
    transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnterNext {
  from {
    opacity: 0;
    -moz-transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
    -moz-transform: translate3d(4em, 0, 0);
    -ms-transform: translate3d(4em, 0, 0);
    -o-transform: translate3d(4em, 0, 0);
    transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -moz-transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
    -moz-transform: translate3d(-4em, 0, 0);
    -ms-transform: translate3d(-4em, 0, 0);
    -o-transform: translate3d(-4em, 0, 0);
    transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slbOverlay, .slbWrapOuter {
  z-index: 9999999999999999;
}

.g-recaptcha {
  padding-top: 20px;
  margin-bottom: 20px;
}
