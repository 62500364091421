.c-topbar {
  position: fixed;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 3.5rem 1.3rem;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;

  background: $color-white;

  z-index: 99999;
}

.c-topbar__placeholder {
  height: 70px;
  display: block;
}

.c-topbar__logo {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 160px;
  width: 100%;

  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;

  svg {
    max-width: 160px;
    width: 100%;
  }
}

.c-topbar__left,
.c-topbar__right {
  display: flex;
  align-items: center;
}

.c-topbar__hamburger {
  width: 2rem;

  display: flex;
  flex-direction: column;

  margin-left: 0.85rem;

  cursor: pointer;

  span {
    display: block;
    height: 6px;
    width: 100%;
    background: $color-black;
    margin-top: 5px;

    border-radius: 20px;

    &:first-child {
      margin: 0;

      width: 69%;
    }
  }
}

.c-topbar__icon-round {
  border-radius: 50%;
  border: 1px solid $color-black;
}

.c-topbar__lang-switch {
  margin-left: 1.2rem;

  color: $color-black;
  font-size: 1rem;
  font-weight: 600;

  text-transform: uppercase;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: $color-black;
  }
}

.c-topbar__social {
  width: 1.8rem;
  height: 1.8rem;

  margin-left: 0.5rem;
}

.c-topbar__shop-link {
  width: 1.3rem;

  margin-left: 1.2rem;
}

.hide-mobile {
  @media (max-width: 480px) {
    display: none;
  }
}