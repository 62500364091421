.c-about-us {
  @include bp(medium) {
    display: flex;
  }
}
.c-about-us__image,
.c-about-us__content {
  @include bp(medium) {
    width: 50%;
  }
}

.c-about-us__image {
  background-size: cover;

  .c-about-us__ph {
    padding-bottom: 100%;
    @include bp(medium) {
      padding-bottom: 0;
    }
  }
}

.c-about-us__content {
  background: $color-super-light-grey;
  padding: 2rem;
  @include bp(medium) {
    padding: 5rem 5rem 3rem;
  }
}

.c-about-us__title {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 2.2rem;
}

.c-about-us__content {
  letter-spacing: 1px;
  line-height: 1.6;
}