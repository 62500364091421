.c-link-with-icon {
  display: block;
  text-decoration: none;
  color: $color-black;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 1px;

  margin-top: 1rem;

  display: flex;
  align-items: center;

  &:hover {
    color: $color-black;
  }
}

.c-link-with-icon__draw {
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid $color-black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;

  &--download {
    svg {
      width: 0.9rem;
    }
  }

  &--question {
    &:before {
      content: "?";
    }
  }


}