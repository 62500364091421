.c-table__bg {
  background: $color-super-light-grey;
  padding: 4rem 1rem;
}

.c-table__title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
}

.c-table__wrapper {
  max-width: 1030px;
  width: 100%;
  margin: 0 auto;
}

.c-table {
  border-bottom: 1px solid $color-black;
  margin-bottom: 3rem;
  overflow: auto;

  th {
    text-transform: uppercase;
    padding: 1rem 0.5rem;
  }

  tr {
    text-align: center;
  }

  td {
    padding: 2rem 1rem 0;

    &[data-content*="•"] {
      font-size: 3rem;
      line-height: 0;
    }
  }

  thead {
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
  }
}

