/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  min-height: 60vh;
  background-size: cover;
  background-position: 50% 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: relative;

  &--product {
    background: $color-super-light-grey;
    height: auto;
    padding: 5rem 1rem;
  }
}

.c-header__slider-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin: 0 1rem;

  &--left,
  &--right {
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    margin-top: 25px;

    > img {
      width: 16px;
      height: 16px;
    }
  }

  &--right {
    transform: rotate(180deg);
  }
}

.c-header__slider {
  position: absolute;
  min-height: 60vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.c-header__slider-item {
  min-height: 60vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.c-header-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  .c-header-scroll {
    display: inline-block;
    font-size: 0.85rem;
    text-transform: uppercase;
    color: $color-white;
    padding: 5px;
    text-decoration: none;

    &--product {
      .svg-mouse {
        fill: $color-black;
      }
    }

    span {
      position: relative;
      margin-right: 10px;
      font-weight: 700;
      top: 2px;
      text-transform: uppercase;
    }

    img {
      width: 35px;
      height: 35px;
    }
  }
}

.svg-mouse {
  fill: $color-white;
  width: 2rem;
  display: block;
  margin: 0 auto;
  animation: arrowMove 1.5s infinite;

  &--arrow {
    margin-top: 0.3rem;
    width: 0.8rem;
  }
}

@keyframes arrowMove {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  to {
    transform: translateY(0);
  }
}

.c-header__icon {
  display: inline-block;
  width: 80px;
  height: 80px;

  border: 2px solid $color-black;
  border-radius: 50%;

  position: relative;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 45px;
  }
}

.c-header__products {
  margin: 0;
}

.c-header__product {
  list-style-type: none;

  a {
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0.2rem;
    color: $color-black;

    &:before {
      content: "▷";
      display: inline;
      margin-right: 10px;
      font-size: 10px;
      vertical-align: middle;
      font-weight: 600;
    }
  }
  &.active {
    a {
      font-weight: 700;
    }
  }
}

.c-header__content-title {
  margin: 0;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $color-black;
}