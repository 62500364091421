.c-gallery__wrapper {
  max-width: 840px;
  width: 100%;
  margin: 3rem auto 0 !important;

  display: flex;
  flex-wrap: wrap;
}

.c-gallery__item {
  width: calc(50% - 3rem);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1.5rem;

  @include bp(medium) {
    width: calc(33% - 3rem);
  }
}

.c-gallery__item-ph {
  padding-bottom: 100%;
}

.c-gallery__btn-wrapper {
  display: display;

  text-align: center;

  margin: 0.5rem auto 1.5rem;
}