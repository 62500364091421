.c-newsletter {
  &--short {
    background: $color-green-blue;
    padding: 2rem 1.2rem;

    text-align: center;

    color: $color-white;

    @include bp(medium) {
      text-align: left;
    }
  }
}

.c-newsletter__wrapper {
  @include bp(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.c-newsletter__text {
  @include bp(medium) {
    width: 35%;
  }
}

.c-newsletter__form {
  display: flex;

  margin-top: 2rem;

  @include bp(medium) {
    width: 35%;
    margin: 0;
  }
}