.c-circle-icon {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 2px solid $color-black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.c-circle-icon--img {
  width: 3.5rem;
  height: 3.5rem;
}
